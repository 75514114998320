* {
  box-sizing: border-box;
}

body {
  height: 100%;
  overflow: hidden;
  margin: 0px;
  font-family: "Helvetica Neue", Helvetica, arial, sans-serif, serif, monospace !important;
  color: var(--font-color);
  font-size: var(--regular-font-size);
}

body :focus {
  outline: none;
}

input::placeholder,
textarea::placeholder {
  opacity: 0.7;
}

input[type="checkbox"] {
  margin-bottom: 15px;
}

a.unstyled {
  color: inherit;
  text-decoration: none;
}

svg.path-stroke {
  fill: transparent !important;
}
svg.path-stroke path {
  stroke: #fff;
}

.error {
  color: var(--error-color);
}

.error-text {
  font-size: 13px;
  line-height: 22px;
  margin-top: 4px;
  margin-bottom: 8px;
  text-align: left;
  width: 100% !important;
  color: var(--error-color);
}

#baLoySectionWrapper {
  height: 100%;
  width: 100%;
}

.referral-body {
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  position: relative;
  z-index: 99999999;
  background: #fff;
  margin-left: auto;
  margin-right: auto;
}

.referral-body.overlay {
  max-width: 1100px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 10%), 0 20px 60px 0px rgb(0 0 0 / 15%);
  height: 635px;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.referral-body.background-cover {
  background-position: top;
}

.referral-body.background-left {
  flex-direction: row-reverse;
}

.referral-body.background-cover,
.background-image {
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center center;
}

.referral-body.background-cover .content {
  text-align: center;
  max-width: 470px;
  margin: 130px 0 130px 115px;
  z-index: 2;
  background: #fff;
  position: relative;
  height: fit-content;
}

.referral-body.background-cover.background-left .content {
  margin: 130px 115px 130px 0px;
}

.close-modal-overlay,
.close-button {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 2147483649;
  width: 30px;
  height: 30px;
  cursor: pointer;
  padding: 5px;
}

.close-button {
  top: 5px;
  right: 5px;
}

.close-button:hover {
  fill: #000;
  opacity: 0.8;
}

.close-modal-overlay {
  background-color: rgba(0, 0, 0, 0.3);
}

.close-modal-overlay svg {
  fill: #fff;
}

.mobile-image {
  display: none;
  margin: -15px -15px 15px;
  background-repeat: no-repeat;
  background-size: cover;
}

.content {
  width: 60%;
}

.content-wrapper {
  position: relative;
  padding: 0px 30px 30px 30px;
  text-align: left;
}

.content-title {
  margin-top: 30px;
  color: var(--primary-color);
  display: block;
  font-size: 2em;
  font-weight: bold;
}

.content-subtitle {
  margin-top: 15px;
}

.content .header {
  background-color: #efefef;
  padding: 15px;
  font-size: 18px;
  font-weight: 500;
}

.section-title {
  margin-bottom: 30px;
}
.section-body {
  margin-bottom: 30px;
}

.section-footer {
  text-align: center;
  margin: 0 auto;
  color: #ccc;
  font-size: 12px;
}

a.email-stats {
  color: var(--subtitle-color);
  text-decoration: underline;
}

.form {
  padding-bottom: 20px;
}

.form-input,
.txt-field {
  border: 1px solid #ddd;
  line-height: 22px;
  padding: 14px 16px;
  text-decoration: none;
  width: 100%;
  word-wrap: break-word;
  background-color: #fff;
  margin-bottom: 14px;
  border-radius: 0;
  font-size: var(--regular-font-size);
}

label.label-with-checkbox{
  display: flex;
  gap: 5px;
  margin-bottom: 15px;
  align-items: center;
  font-size: var(--regular-font-size);
  color: var(--font-color);
}

form input.checkbox{
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: var(--regular-font-size);
}

label {
  font-size: var(--regular-font-size);
}

.form-label {
  display: block;
  line-height: 1.2rem;
  padding: 0.3rem 0;
  font-weight: 600;
}

.txt-field.error {
  border-color: var(--error-color);
  margin-bottom: 5px;
}

.icon-and-text-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.icon-and-text-row svg {
  fill: #fff;
}

.tab-container {
  border: 1px solid #bbb;
}

.tab-list {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
}

.tab-list li {
  flex: 1;
  text-align: center;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  padding: 10px 0px;
}

.tab-list a {
  display: list-item;
}

.tab-list li.selected {
  border-bottom: 3px solid #000;
}

.tab-list svg.path-stroke path {
  stroke: #000;
}

.tab-list li:not(:first-child) a {
  border-left: 1px solid #ddd;
}

.tab-item.active {
  display: block;
}
.tab-item:not(.active) {
  display: none;
}

.tab-content {
  padding: 30px 15px;
}

.tab-content .form {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.button {
  position: relative;
  color: #fff;
  border-radius: 4px;
  background-color: #14433d;
  border-color: #14433d;
  border-style: solid;
  font-size: 18px;
  line-height: 21px;
  padding: 13px 40px;
  cursor: pointer;
  width: 100%;
}

.content.link-mode-exposed .sharing-buttons {
  border-top: 1px solid #ccc;
  margin-top: 20px;
}

.sharing-buttons ul {
  list-style: none;
  padding-left: 0px;
}

.sharing-buttons li button.button {
  margin-bottom: 15px;
}

input.referral-link {
  background-color: #f2f2f2;
  font-weight: 600;
  font-size: 11px;
}

input.referred_reward_code {
  background-color: #f2f2f2;
  font-weight: 600;
  border: 1px dashed #14433d;
  border-right: none;
}

.popup-page {
  top: 10px;
  bottom: 10px;
  background-color: #fff;
  left: 38.5%;
  padding: 20px;
  position: absolute;
  right: 1%;
  text-align: left;
  z-index: 999;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 12%), 0 5px 35px rgb(0 0 0 / 18%);
  overflow: auto;
}

.background-left .popup-page {
  left: 1%;
  right: 38.5%;
}

.background-cover .popup-page {
  left: inherit;
  width: 96%;
  right: 2%;
}

.popup-page.active {
  display: block !important;
}

.popup-page.share-link .copy-coupon {
  flex-direction: column;
  gap: 20px;
}

.personal-link.blocks .copy-coupon {
  flex-direction: column;
  gap: 10px;
}

.personal-link.blocks label {
  font-size: 12px;
  padding-bottom: 10px;
  display: block;
}

.popup-page.share-link .copy-coupon button.button {
  width: fit-content;
}

.toast {
  color: #fff;
  border-radius: 0.1rem;
  display: block;
  padding: 0.4rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 25px;
  font-size: var(--regular-font-size);
  font-weight: 500;
  text-align: center;
}

.toast.success {
  background: rgba(50, 182, 67, 0.95);
  border-color: #32b643;
}

.loy-copy-success {
  font-size: 0px !important;
}

.loy-copy-success svg {
  display: none;
}

.loy-copy-success:before {
  content: "";
  background-color: inherit;
  width: 30px;
  height: 25px;
  z-index: 1;
  top: -5;
  left: 0;
  right: -5px;
  bottom: 0;
  margin: auto;
  display: block;
  position: absolute;
  opacity: 0.7;
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%0A%3E%3Cpath d='M10.5858 13.4142L7.75735 10.5858L6.34314 12L10.5858 16.2427L17.6568 9.1716L16.2426 7.75739L10.5858 13.4142Z' fill='%238d8d8f' fill-rule='non-zero' /%3E%3C/svg%3E");
}

.loy-button-loading {
  font-size: 0px !important;
}

.loy-button-loading:before,
.ajax-interceptor:before {
  content: "";
  background-color: inherit;
  width: 20px;
  height: 20px;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: block;
  position: absolute;
  border-radius: 50%;
  font-size: 4px;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(219, 219, 219, 0.2);
  border-right: 1.1em solid rgba(219, 219, 219, 0.2);
  border-bottom: 1.1em solid rgba(219, 219, 219, 0.2);
  border-left: 1.1em solid #dbdbdb;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 0.6s infinite linear;
  animation: load8 0.6s infinite linear;
}

.loy-button-loading:after,
.ajax-interceptor:after {
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
  z-index: 0;
  content: "";
  display: block !important;
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
}

@media (max-width: 980px) {
  .content {
    width: 70%;
  }
}

@media (max-width: 680px) {
  .md-full-width {
    width: 100%;
  }

  .content {
    width: 100%;
  }

  .referral-body.background-cover .content {
    margin: 0px !important;
    max-width: inherit;
  }

  .advocate-reward.content,
  .friend-reward.content,
  .friend-claim.content {
    margin-top: 0px !important;
  }

  .background-image {
    display: none;
  }

  .background-cover {
    background-image: none !important;
  }

  .mobile-image {
    display: block;
    height: 230px;
  }

  .content .header {
    background-color: transparent !important;
    padding: 15px;
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;
  }

  .popup-page {
    bottom: 0;
    height: auto !important;
    left: 0 !important;
    margin: 0 !important;
    right: 0 !important;
    top: 0 !important;
    width: auto !important;
  }
}
.mb-0 {
  margin-bottom: 0px;
}
.mt-0 {
  margin-top: 0px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mt-20 {
  margin-top: 20px;
}
.pb-0 {
  padding-bottom: 0px;
}
.pt-0 {
  padding-top: 0px;
}
.dflex {
  display: flex;
}
.gap-10 {
  gap: 10px;
}

.copy-coupon.dflex button.button {
  position: relative;
  width: inherit;
  padding: 13px 15px;
  border-radius: inherit;
  min-width: 100px;
}

.apply-discount {
  display: inline-flex;
}

.advocate-reward.content,
.friend-reward.content,
.friend-claim.content {
  margin-top: 80px;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes faderOn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes faderOn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@-webkit-keyframes slideAndFade {
  0% {
    opacity: 0;
    transform: translate(20px);
  }

  80% {
    opacity: 1;
  }

  to {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes slideAndFade {
  0% {
    opacity: 0;
    transform: translate(20px);
  }

  80% {
    opacity: 1;
  }

  to {
    opacity: 1;
    transform: translate(0);
  }
}

@-webkit-keyframes faderOut {
  0% {
    opacity: 0;
    transform: translate(-10px);
  }

  to {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes faderOut {
  0% {
    opacity: 0;
    transform: translate(-10px);
  }

  to {
    opacity: 1;
    transform: translate(0);
  }
}
